@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400;700&display=swap');

body
{
    width: 100%;
    height: 100%;
    font-family: 'Rubik', sans-serif;
}

.content
{
    width: 100%;
    height: 100%;
}

h1, h2
{
    color: #c48f58;
}

.mainBox
{
    width: 80%;
    max-width: 600px;
    margin: 0 auto;
    margin-top: 20px;
}

.topMain
{
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
}

.btn
{
    background-color: #29262f;
    border: none;
    padding: 10px 20px;
    margin: 0 5px;
    color: #fff;
    font-weight: bold;
    cursor: pointer;
    border-radius: 20px;
}

.btnmin
{
    background-color: #fff;
    border: none;
    padding: 5px;
    margin: 0 5px;
    color: #29262f;
    font-weight: bold;
    cursor: pointer;
}

.btnmin2
{
    background-color: #29262f;
    border: none;
    padding: 6px 10px;
    margin: 0 5px;
    color: #fff;
    font-weight: bold;
    cursor: pointer;
    border-radius: 5px;
}

.btnmin3
{
    background-color: #29262f;
    border: none;
    padding: 6px 10px;
    color: #fff;
    font-weight: bold;
    cursor: pointer;
    border-radius: 5px;
    margin-top: 10px;
}

.btnmin4
{
    background-color: #29262f;
    border: none;
    padding: 6px 10px;
    color: #fff;
    font-weight: bold;
    cursor: pointer;
    border-radius: 5px;
    margin-top: 5px;
}

.btn:hover, .btnmin2:hover, .btnmin3:hover, .btnmin4:hover
{
    background-color: #929292 !important;
}


.topMain button:hover, .btnmin:hover
{
    background-color: #29262f;
    color: #fff;
}

.clear
{
    clear: both;
}

.loadingMsg
{
    width: 100%;
    text-align: center;
    margin-top: 30px;
}

/*LOGIN*/
.loginBox
{
    width: 80%;
    max-width: 600px;
    background-color: #29262f;
    margin: 0 auto;
    margin-top: 30px;
    margin-bottom: 30px;
    padding: 5%;
    color: #fff;
    text-align: center;
    border-radius: 30px;
}

.registerBox
{
    width: 80%;
    max-width: 500px;
    background-color:#EBEBEA;
    margin: 0 auto;
    margin-top: 30px;
    margin-bottom: 30px;
    padding: 2%;
    color: #29262f;
    text-align: center;
    border-radius: 30px;
}

.loginFields
{
    width:80%;
    margin: 0 auto;
    text-align: left;
    margin-bottom: 30px;
}

.registerFields
{
    width:90%;
    margin: 0 auto;
    text-align: left;
    margin-bottom: 30px;
}

.loginField
{
    width: 100%;
    margin-bottom: 20px;
}

.loginFields label, .registerFields label
{
   display: block;
   font-size: 14px;
   font-weight: bold;
   margin-bottom: 3px;
}

.loginFields input
{
   display: block;
   width: calc(100% - 40px);
   padding: 10px 20px;
}

.loginField input[type="file"]
{
    padding: 10px 0px;
}

.registerFields input
{
   display: block;
   width: calc(100% - 40px);
   padding: 5px 20px;
}

.registerFields select
{
   display: block;
   width: 50%;
   padding: 5px 20px;
}

.btnBig
{
    background-color: #EBEBEA;
    padding: 2%;
    color: #29262f;
    border: none;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
}

.btnBig2
{
    background-color: #29262f;
    padding: 2% 20px;
    color: #fff;
    border: none;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    border-radius: 20px;
}

.btnBig:hover
{
    background-color: #939293;
    color: #fff
}

.btnBig2:hover
{
    background-color: #929292;
}

/*TOP*/
.top
{
    width: 100%;
    height: auto;
    background-color: #29262f;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 20px;
}

.menuLogo
{
    margin-bottom: 10px;
}

.menuLink
{
    color: #fff;
    text-decoration: none;
    margin: 0 10px;
    font-size: 14px;
    font-weight: bold;
}

/*LIST*/
.listBox
{
    width: calc(100% - 20px);
    display: block;
    padding: 10px;
    background-color: #f5f5f5;
    margin-bottom: 1px;
    text-decoration: none;
    color: #29262f
}

.listBox2
{
    width: calc(100% - 20px);
    display: block;
    padding: 10px;
    background-color: #f5f5f5;
    margin-bottom: 1px;
    text-decoration: none;
    color: #29262f
}

.listBox:hover
{
    background-color: #29262f;
    color: #fff
}

.listBoxSec1
{
    width: 100%;
    display: flex;
    align-items: center;
}

.listBoxSec2
{
    width: 100%;
}

.listBox_im
{
    width: 50px;
    height: 50px;
    border-radius: 25px;
    background-color: #DEDEDE;
}

.listBox_im2
{
    width: 100%;
    height: auto;
    margin-bottom: 10px;
}

.listBox_data
{
    margin-left: 10px;
}

.listBox_im img
{
    width: 100%;
    height: 100%;
    border-radius: 25px;
}

.listBox_im2 img
{
    width: 100%;
    height: 100%;
}

.listBox_main
{
    margin: 0;
    font-size: 15px;
    font-weight: bold;
}

.listBox_sec
{
    margin: 0;
    font-size: 12px;
}

/*PERFL*/
.perfilBox
{
    width: 80%;
    max-width: 600px;
    padding: 30px;
    margin: 0 auto;
    margin-top: 30px;
    background-color: #f5f5f5;
    border-radius: 30px;
}

.perfilBtns
{
    width: 100%;
    margin-bottom: 40px;
}

.perfilLink
{
    color: #fff;
    text-decoration: none;
    margin-right: 5px;
    font-size: 14px;
    font-weight: bold;
    background-color: #29262f ;
    padding: 5px 20px;
    border-radius: 20px;
}

.perfilLink:hover
{
    background-color: #929292;
}

.perfilData
{
    width: 100%;
}

.perfilTop
{
    display: flex;
    align-items: center;
}

.promoTop
{
    display: block;
}

.perfilBox_im
{
    width: 100px;
    height: 100px;
    border-radius: 50px;
    background-color: #DEDEDE;
}

.perfilBox_im img
{
    width: 100%;
    height: 100%;
    border-radius: 50px;
}

.promoBox_im
{
    width: 100%;
    height: auto;
}

.promoBox_im img
{
    width: 100%;
    height: 100%;
}

.promoBox_name
{
    margin-left: 0px;
}

.perfilBox_name
{
    margin-left: 10px;
}

.perfilBox_nameT
{
    font-size: 26px;
    margin: 0;
}

.perfilBox_nameG
{
    font-size: 16px;
    margin: 0;
}

.perfilMid
{
    margin-top: 30px;
}

.perfilMid_box
{
    width: 100%;
    margin-bottom: 10px;
}

.perfilMid_t
{
    margin: 0;
    font-size: 14px;
    font-weight: bold;
}

.perfilMid_s
{
    margin: 0;
    font-size: 16px;
}

.perfilPointsarea
{
    margin-top: 30px;
}

.perfilPoints
{
    margin-bottom: 10px;
}

.perfilPoints input
{
    padding: 4px 0;
    width: 50px;
    text-align: center;
}

/*POPUP*/
.popup
{
    width: 100%;
    height: 100%;
    position: fixed;
    background-color: rgba(000, 000, 000, .6);
    display: none;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.popbox
{
    width: 60%;
    max-width: 500px;
    padding: 30px;
    background-color: #fff;
    position: relative;
}

.closepop
{
    position: absolute;
    right:10px;
    top:5px;
    font-weight: bold;
    font-size: 30px;
    margin: 0;
    cursor: pointer;
}

.closepop:hover
{
    opacity: .8;
}

.popfile
{
    margin-bottom: 20px;
}

/*FOTOS*/
.fotosBox
{
    width: 80%;
    max-width: 600px;
    margin: 0 auto;
}

.fotoBox
{
    width: 100%;
    margin-bottom: 10px;
    background-color: #D8D8D8;
    position: relative;
    text-align: center;
}

.fotoBox_im
{
    width: 100%;
}

.fotoBox_im img
{
    width: 100%;
    height: auto;
}

.fotoBox_data
{
    width: 100%;
    background-color: rgba(0, 0, 0, .6);
    padding: 20px;
    padding-bottom: 40px;
    position: absolute;
    bottom: 0;
    left: 0;
    color: #fff;
}

.fotoBox_data button
{
    margin-top: 10px;
}